export enum DrawerEvent {
  Open = "drawer:open",
  Close = "drawer:close",
  Toggle = "drawer:toggle",
}

const EVENT = {
  HEADER: {
    WHITE: 'header:white',
  },
  CART: {
    UPDATE: 'cart:update',
  },
  ACCORDION: {
    DRAWERCLOSED: 'accordion-drawer:closed',
  },
};

export default EVENT;
